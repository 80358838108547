'use strict';

const cache = {
  $body: $('body'),
  $verifycode: $('#verify-zip-code'),
  $serviceSection: $('#service-section')
}

let verifyPressed;

function clearAllMessages() {
  $('.alert').remove();
}

function showErrorMessage(form, message) {
  form.append('<div class="alert alert-danger mx-auto mt-2">' + message + '</div>')
}

function showSuccessMessage(form, message) {
  form.append('<div class="alert alert-info mx-auto mt-2">' + message + '</div>')
}

function showZipModal() {
  verifyPressed = false;
  const urlContent = cache.$serviceSection.data('url');
  $.spinner().start();
  $.ajax({
    url: urlContent,
    type: 'get',
    dataType: 'html',
    success(res) {
      $.spinner().stop();
      cache.$body.append(res);
      $('#zip-code-modal').modal('show');
    },
    error(error) {
      console.error(error);
      $.spinner().stop();
    },
  });
}

module.exports = function () {
  cache.$verifycode.on('click', () => {
    showZipModal();
  });

  cache.$body.on('hidden.bs.modal', '#zip-code-modal', function (e) {
    $(this).remove();
  });

  cache.$body.on('submit', '#zip-form', function (e) {
    e.preventDefault();

    verifyPressed = true;

    const $form = $(this);
    const url = $form.attr('action');
    const method = $form.attr('method');
    const data = $form.serialize();

    const zipCode = $form.serializeArray().find((item) => item.name === 'dwfrm_profile_customer_address__backup_postalCode').value;

    console.log(zipCode);

    $form.spinner().start();
    clearAllMessages();
    $.ajax({
      url,
      method,
      data,
      dataType: 'json',
      success(data) {
        $form.spinner().stop();

        cache.$body.trigger('Tracking:ZipCode', zipCode);

        if (data.error) {
          showErrorMessage($form, data.message);

        } else {
          showSuccessMessage($form, data.message);

        }
      },
      error(error) {
        console.error(error);
        $form.spinner().stop();
      }
    })
  });
}
