"use strict";

const cache = {
    $body: $('body'),
    $loginContainer: '.loginContainer',
    $resetModalPassword: '.js-reset-modal-password',
    $forgotPassword: '.forgot-password',
    $eyePassworShow: '.js-eyePasswordShow',
    $loginModalButton: '.loginModalButton',
    $eyePasswordNotShow: '.js-eyePasswordNotShow',
    $loginFormPassword: '.js-form-password',
    $eyePasswordShow: '.js-eyePasswordShow',
    $newCustomer: '.newCustomer',
    $responseresetmodalpassword: $('.js-response-reset-modal-password')
}

module.exports = function () {
    cache.$body
        .on('click', '.js-password-reset', function (e) {
            e.preventDefault();
            const $this = $(this);
            const parent = $this.parents('.account-login-form');

            parent.find(cache.$loginContainer).addClass('d-none');
            parent.find(cache.$resetModalPassword).removeClass('d-none');
            parent.find(cache.$forgotPassword).addClass('d-none');

        }).on('click', '.js-login-modal', function (e) {
            e.preventDefault();
            const $this = $(this);
            const parent = $this.parents('.account-login-form');

            parent.find(cache.$newCustomer).removeClass('d-none');
            parent.find(cache.$loginContainer).removeClass('d-none');
            cache.$responseresetmodalpassword.empty();
            parent.find(cache.$resetModalPassword).addClass('d-none');
            parent.find(cache.$forgotPassword).removeClass('d-none');
        })
        .on('click', '.js-eyePasswordNotShow', function (e) {
            e.preventDefault();
            const $this = $(this);
            const parent = $this.parent();

            parent.find(cache.$loginFormPassword).attr('type', 'text');
            parent.find(cache.$eyePasswordShow).removeClass('d-none');
            $this.addClass('d-none');
        })
        .on('click', '.js-eyePasswordShow', function (e) {
            e.preventDefault();
            const $this = $(this);
            const parent = $this.parent();

            parent.find(cache.$loginFormPassword).attr('type', 'password');
            $this.addClass('d-none');
            parent.find(cache.$eyePasswordNotShow).removeClass('d-none');
        });
};
