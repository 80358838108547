const Interval = function (callback, time) {
    let isPaused;
    let timer;

    this.resume = function () {
        isPaused = false;
        timer = setInterval(() => {
            if (!isPaused) {
                return callback();
            }
        }, time);
    };

    this.pause = function () {
        isPaused = true;
    };

    this.stop = function () {
        clearInterval(timer);
    }

    this.resume();
}

module.exports = Interval;