'use strict';

const cache = {
  $body: $('body'),
  $window: $(window),
  $header: $('header'),
  $page: $('.page'),
  $hamburgerMenu: $('.js-hamburger-menu'),
  $menuSecondLevel: $('.menuSecondLevel'),
  $subMenuDropdown: $('.subMenuDropdown'),
  $menuFirstLevel: $('.menuFirstLevel'),
  $addressSelectorBadge: $('#addressSelectorBadge'),
  $addressHeaderModal: $('#addressHeaderModal'),
  $pricebookChangedModal: $('#pricebookChanged-modal'),
  $containerProductMiniCart: $('.js-containerProductMiniCart'),
  $inventoryIcon: $('.js-inventoryIcon')
}, limitScroll = 100;

const shipping = require('../checkout/shipping');

let menuOpen = false;

const isDesktop = cache.$window.width() > 768;

function getNewInventory() {
  const url = cache.$inventoryIcon.data("url");
  $.ajax({
    url,
    type: 'GET',
    dataType: 'html',
    success(data) {
      cache.$inventoryIcon.empty().append(data);
    },
    error(err) {
      window.location.reload();
    },
  });
};

module.exports = () => {
  if (navigator.userAgent.match(/android-custom/i) || navigator.userAgent.match(/iphone-custom/i)) {
    cache.$header.addClass('d-none');
    cache.$header.css('height', 0);
    cache.$page.css('paddingTop', 0);
  }

  cache.$body.on('windowScroll', function (e, window) {
    const target = window.currentTarget;

    if (isDesktop) {
      if (target.scrollY > limitScroll) {
        cache.$page.addClass('small-header');
      } else {
        cache.$page.removeClass('small-header');

        if (cache.$page.hasClass('hamburger-open')) {
          cache.$page.removeClass('hamburger-open');
          cache.$hamburgerMenu.removeClass('hamburger-open');
        }
      }
    }
  })
    .on('click', '.js-hamburger-menu', function () {
      const $this = $(this);

      if (menuOpen) {
        $this.removeClass('hamburger-open');
        cache.$page.removeClass('hamburger-open');
        menuOpen = false;
      } else {
        $this.addClass('hamburger-open');
        cache.$page.addClass('hamburger-open');
        menuOpen = true;
      }
    })
  // .on('hover', '.first-level-item', setMenuHeight);

  cache.$addressSelectorBadge.on('change', '.js-header-address-input', () => {
    // Ottenere il valore del radio button selezionato
    const selectedAddress = $('input[name=address-header]:checked').val();
    const selectedUrl = $('input[name=address-header]:checked').data('setdefault');

    // Eseguire l'azione desiderata utilizzando il valore selezionato
    cache.$body.spinner().start();

    $.ajax({
      url: selectedUrl,
      method: "POST",
      data: {
        addressID: selectedAddress,
      },
      success(data) {
        if (!data.success) {
          return;
        }
        cache.$body.trigger('addressForm:Update', { adddressUUID: selectedAddress });

        if(cache.$inventoryIcon.length){
          getNewInventory();
        }
        cache.$body.spinner().stop();

        if (data.pricebookChanged) {
          cache.$pricebookChangedModal.removeClass('hide');
          cache.$pricebookChangedModal.addClass('show');
          cache.$containerProductMiniCart.empty().append(data.miniCartTemplate.miniCartHTML);

          shipping.pricebookChangedConfirmBtn();
        }
      },
      error(err) {
        if (err.responseJSON.redirectUrl) {
        }
        cache.$body.spinner().stop();
      },
    });
  });

}
