'use strict';

const Interval = require('./interval');

const cache = {
    $body: $('body')
}

const timerQueue = {};
function progressBarMove($progressBar, $toast, id) {
    let width = $progressBar.width();
    timerQueue[id] = new Interval(() => {
        if (width <= 0) {
            closeToast($toast);
            timerQueue[id].stop()
        } else {
            width--;
            $progressBar.css('width', width + 'px');
        }
    }, 5);
}

function closeToast($target) {
    const $toastContainer = $target.parents('.toast-container');
    close($target, $toastContainer);
}

// trigger animation
function close($target, $toastContainer) {
    $target.removeClass('open');

    setTimeout(() => {
        $target.remove();

        if (!$('.toast').length) {
            $toastContainer.remove();
        }
    }, 500);
}

// trigger animation
function show($target) {
    setTimeout(() => {
        $target.addClass('open');
    }, 50);
}

function showToastProduct(id, $target, params) {
    $target.append(
        `<div data-toast="${id}" class="toast bg-grey11 depth-z100 full-width mb-3 p-3">
            <div class="toast-image width-100" style="background-image: url(${params.image})"></div>
            <div class="toast-text full-width pl-8 pr-3">
                <h3 class="size-small cl-blue bold">${params.productName}</h3>
                <p class="size-tiny cl-blue family-light">${params.code}</h3>
            </div>
            <button type="button" class="icon-close icons pointer size-tiny position-absolute width-42 pointer right-0 px-3 absolute-vertical-center full-height" data-dismiss="toast"></button>
            <div class="z-index-footer full-width position-absolute left-0 bottom-0">
                <div class="h-1 progress-bar progress-bar-${id} full-width bg-grey1"></div>
            </div>
        </div>`
    )
}

function showToastWishlist(id, $target, message, params) {
    $target.append(
        `<div data-toast="${id}" class="toast bg-blue1 depth-z100 full-width mb-3 p-3">
            <div class="toast-text full-width">
                <h3 class="size-small cl-blue bold">${params.productName}</h3>
                <p class="size-tiny cl-blue family-light">${message}</h3>
            </div>
            <button type="button" class="icon-close icons pointer size-tiny position-absolute width-42 pointer right-0 px-3 absolute-vertical-center full-height" data-dismiss="toast"></button>
            <div class="z-index-footer full-width position-absolute left-0 bottom-0">
                <div class="h-1 progress-bar progress-bar-${id} full-width bg-grey1"></div>
            </div>
        </div>`
    );
}

function showToastError(id, $target, message) {
    $target.append(
        `<div data-toast="${id}" class="toast bg-red cl-white depth-z100 full-width mb-3 p-3">
            <div class="toast-text full-width">
                <p class="size-small cl-white family-light">${message}</h3>
            </div>
            <button type="button" class="icon-close icons pointer size-tiny position-absolute width-42 pointer right-0 px-3 absolute-vertical-center full-height" data-dismiss="toast"></button>
            <div class="z-index-footer full-width position-absolute left-0 bottom-0">
                <div class="h-1 progress-bar progress-bar-${id} full-width bg-grey1"></div>
            </div>
        </div>`
    );
}

function showToastSuccess(id, $target, message) {
    $target.append(
        `<div data-toast="${id}" class="toast bg-green cl-white depth-z100 full-width mb-3 p-3">
            <div class="toast-text full-width">
                <p class="size-small cl-white family-light">${message}</h3>
            </div>
            <button type="button" class="icon-close icons pointer size-tiny position-absolute width-42 pointer right-0 px-3 absolute-vertical-center full-height" data-dismiss="toast"></button>
            <div class="z-index-footer full-width position-absolute left-0 bottom-0">
                <div class="h-1 progress-bar progress-bar-${id} full-width bg-grey1"></div>
            </div>
        </div>`
    );
}

/**
 * Show a spinner inside a given element
 * @param {Object} $target - toast-object
 *                            
 */
function showToast({ type, message, orientation, params }) {
    const id = Date.now();
    let position;

    switch (orientation) {
        case 'top-right':
            position = 'top-0 right-0 mt-11-lg mt-9-md mt-6-sm mt-2 mr-2';
            break;
        case 'top-left':
            position = 'top-0 left-0 mt-11-lg mt-9-md mt-6-sm mt-2 ml-2';
            break;
        case 'bottom-left':
            position = 'bottom-0 left-0 mb-2 ml-2';
            break;
        case 'bottom-right':
            position = 'bottom-0 right-0 mb-2 mr-2';
            break;
        default:
            position = 'top-0 right-0 mt-11-lg mt-9-md mt-6-sm mt-2 mr-2';
            break;
    }

    if (!$(`[data-toast-type="${type}"]`).length) {
        cache.$body.append(
            `<div data-toast-type="${type}" class="toast-container ${position} position-fixed full-width max-w-30 z-index-above-all"></div>`
        );
    }

    const $toastContainer = $(`[data-toast-type="${type}"]`);

    switch (type) {
        case 'product':
            showToastProduct(id, $toastContainer, params)
            break;
        case 'success':
            showToastSuccess(id, $toastContainer, message);
            break;
        case 'wishlist':
            showToastWishlist(id, $toastContainer, message, params);
            break;
        case 'error':
            showToastError(id, $toastContainer, message);
            break;
    }

    const $toast = $(`.toast[data-toast="${id}"]`);
    const $progressBar = $(`.progress-bar-${id}`);
    show($toast);
    progressBarMove($progressBar, $toast, id);
}

$.toast = () => {
    const Fn = function () {
        this.start = (obj) => {
            showToast(obj);
        }
    }

    return new Fn();
}

function init() {
    cache.$body
        .on('click', '[data-dismiss="toast"]', (e) => {
            const target = $(e.currentTarget).parents('.toast');
            // console.log(target);
            closeToast(target);
        })
        .on('mouseover', '.toast', (e) => {
            const id = $(e.currentTarget).data('toast');
            // console.log(timerQueue, id);
            timerQueue[id].pause();
        })
        .on('mouseleave', '.toast', (e) => {
            const id = $(e.currentTarget).data('toast');
            // console.log(timerQueue, id);
            timerQueue[id].resume();
        });
}

jQuery(function () {
    init();
});