"use strict";

module.exports = () => {
    // bind change event to select
    $('#country-select').on('change', function () {
        var url = $(this).val(); // get selected value
        if (url) { // require a URL
            // window.location = url; // redirect
            window.open(url, '_blank');
        }
        return false;
    });
};
