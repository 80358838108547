'use strict';

var debounce = require('lodash/debounce');
var endpoint = $('.suggestions-wrapper').data('url');
var minChars = 3;
var UP_KEY = 38;
var DOWN_KEY = 40;
var DIRECTION_DOWN = 1;
var DIRECTION_UP = -1;
var typeSearchActive = null;
var searchExtended = false;
// var $headerSearchMobile = $('.header-search-mobile');

var configProductCarousel = {
    "infinite": false,
    "slidesToShow": 3,
    "slidesToScroll": 1,
    "prevArrow": "<span class='slick-arrow slick-prev'></span>",
    "nextArrow": "<span class='slick-arrow slick-next'></span>",
    "responsive": [
        {
            "breakpoint": 992,
            "settings": {
                "slidesToShow": 2,
                "slidesToScroll": 1
            }
        },
        {
            "breakpoint": 768,
            "settings": {
                "slidesToShow": 1,
                "slidesToScroll": 1
            }
        },
    ]
}

const cache = {
    $body: $('body'),
    $document: $(document),
    $header: $('header'),
    $icons: $('.icons'),
    $menuGroup: $('.js-menu-group'),
    $navbarNav: $('.navbar-nav'),
    $searchField: $('.js-search-field'),
    $menuSearch: $('#menu-search'),
    $suggestions: $('.suggestions'),
    $resetButton: $('.js-reset-button'),
    $searchMobile: $('.js-search-mobile'),
    $searchCheckoutContainer: $('.js-search-checkout-container'),
    $searchLensIcon: $('.search-lens'),
}


/**
 * Transform the checkout search bar based on two events:
 * - 'onSearch' when the user starts searching
 * - 'onDismiss' when the user ends the search process
 * - if the event is not specified or invalid, applies the 'onDismiss' behaviour
 *
 * @param {String} event - Parameter that contains event type
 */
function transformCheckoutSearch(event){
    event = event === 'onSearch' ? 'addClass' : 'removeClass';
    cache.$searchCheckoutContainer[event]('position-absolute');
    cache.$searchCheckoutContainer[event]('pt-4');
    cache.$searchLensIcon[event]('z-index-above-all');
    cache.$searchField[event]('z-index-above-all');
    cache.$resetButton[event]('z-index-above-all');
}

/**
 * Retrieves Suggestions element relative to scope
 *
 * @param {Object} scope - Search input field DOM element
 * @return {JQuery} - .suggestions-wrapper element
 */
function getSuggestionsWrapper(scope) {
    return $(scope).siblings('.suggestions-wrapper');
}

/**
 * Remove modal classes needed for mobile suggestions
 *
 */
function clearModals() {
    cache.$body.removeClass('modal-open');
    cache.$header.siblings().attr('aria-hidden', 'false');
    cache.$suggestions.removeClass('modal');
}

/**
 * Apply modal classes needed for mobile suggestions
 *
 * @param {Object} scope - Search input field DOM element
 */
function applyModals(scope) {
    cache.$body.addClass('modal-open');
    cache.$header.siblings().attr('aria-hidden', 'true');
    getSuggestionsWrapper(scope).find('.suggestions').addClass('modal show');

    cache.$body.trigger('buildCarousel', [configProductCarousel, getSuggestionsWrapper(scope).find('.js-suggestion-carousel:visible')]);
}

/**
 * Tear down Suggestions panel
 */
function tearDownSuggestions() {
    cache.$searchField.val('');
    clearModals();
    $('.search-mobile .suggestions').off('scroll');
    $('.suggestions-wrapper').empty();
}

/**
 * Toggle search field icon from search to close and vice-versa
 *
 * @param {string} action - Action to toggle to
 */
function toggleSuggestionsIcon(action) {
    var mobileSearchIcon = '.search-mobile button.';
    var iconSearch = 'fa-search';
    var iconSearchClose = 'fa-close';

    if (action === 'close') {
        $(mobileSearchIcon + iconSearch).removeClass(iconSearch).addClass(iconSearchClose).attr('type', 'button');
    } else {
        $(mobileSearchIcon + iconSearchClose).removeClass(iconSearchClose).addClass(iconSearch).attr('type', 'submit');
    }
}

/**
 * Determines whether the 'More Content Below' icon should be displayed
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 */
function handleMoreContentBelowIcon(scope) {
    if (($(scope).scrollTop() + $(scope).innerHeight()) >= $(scope)[0].scrollHeight) {
        $('.more-below').fadeOut();
    } else {
        $('.more-below').fadeIn();
    }
}

/**
 * Determines whether DOM element is inside the .search-mobile class
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 * @return {boolean} - Whether DOM element is inside  div.search-mobile
 */
function isMobileSearch(scope) {
    return !!$(scope).closest('.js-search-mobile').length;
}

/**
 * Positions Suggestions panel on page
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 */
function positionSuggestions(scope) {
    var top;
    var $suggestions;

    const $header = $('header');

    top = isMobileSearch(scope) ? $header.innerHeight() + cache.$searchMobile.innerHeight() : $header.innerHeight();
    $suggestions = getSuggestionsWrapper(scope).find('.suggestions');
    $suggestions.css('top', top);

    handleMoreContentBelowIcon(scope);

    // Unfortunately, we have to bind this dynamically, as the live scroll event was not
    // properly detecting dynamic suggestions element's scroll event
    $suggestions.on('scroll', function () {
        handleMoreContentBelowIcon(this);
    });

}

/**
 * Process Ajax response for SearchServices-GetSuggestions
 *
 * @param {Object|string} response - Empty object literal if null response or string with rendered
 *                                   suggestions template contents
 */
function processResponse(response) {
    var $suggestionsWrapper = getSuggestionsWrapper(this).empty();

    $.spinner().stop();

    if (typeof (response) !== 'object') {
        if ($suggestionsWrapper.length){
            if (cache.$searchCheckoutContainer.length) transformCheckoutSearch('onSearch');
            $suggestionsWrapper.append(response).addClass('show');
            positionSuggestions(this);

            toggleSuggestionsIcon('close');
            applyModals(this);

            // Trigger screen reader by setting aria-describedby with the new suggestion message.
            var suggestionsList = cache.$suggestions.find('.item');
            if ($(suggestionsList).length) {
                cache.$searchField.attr('aria-describedby', 'search-result-count');
            } else {
                cache.$searchField.removeAttr('aria-describedby');
            }

            if (typeSearchActive) {
                const $productsSuggested = $('.js-products-suggested');
                const selector = $(`[data-id='${typeSearchActive}']`);
                $productsSuggested.addClass('hide');
                selector.removeClass('hide');
                cache.$body.trigger('buildCarousel', [configProductCarousel, cache.$suggestions.find('.js-suggestion-carousel:visible')]);
            }
            //Trigger for Matomo (event only for CH)
            cache.$body.trigger('search:preview', response);
        }

    } else {
        $suggestionsWrapper.removeClass('show');
    }
}

/**
 * Retrieve suggestions
 *
 * @param {Object} scope - Search field DOM element
 */
function getSuggestions(scope) {
    if ($(scope).val().length >= minChars) {
        $.spinner().start();

        const searchFor = typeSearchActive ? `&searchFor=${typeSearchActive}` : '';
        const isCheckoutSearch = cache.$searchCheckoutContainer.length ? '&isCheckoutSearch=true' : '';

        $.ajax({
            context: scope,
            url: endpoint + encodeURIComponent($(scope).val()) + searchFor + isCheckoutSearch,
            method: 'GET',
            success: processResponse,
            error: function () {
                $.spinner().stop();
            }
        });
    } else {
        if (cache.$searchCheckoutContainer.length) transformCheckoutSearch('onDismiss');
        toggleSuggestionsIcon('search');
        $(scope).siblings('.js-reset-button').removeClass('hide');
        clearModals();
        getSuggestionsWrapper(scope).empty();
    }
}

/**
 * Handle Search Suggestion Keyboard Arrow Keys
 *
 * @param {Integer} direction takes positive or negative number constant, DIRECTION_UP (-1) or DIRECTION_DOWN (+1)
 */
function handleArrow(direction) {
    // get all li elements in the suggestions list
    var suggestionsList = cache.$suggestions.find('.item');
    if (suggestionsList.filter('.selected').length === 0) {
        suggestionsList.first().addClass('selected');
        cache.$searchField.each(function () {
            $(this).attr('aria-activedescendant', suggestionsList.first()[0].id);
        });
    } else {
        suggestionsList.each(function (index) {
            var idx = index + direction;
            if ($(this).hasClass('selected')) {
                $(this).removeClass('selected');
                $(this).removeAttr('aria-selected');
                if (suggestionsList.eq(idx).length !== 0) {
                    suggestionsList.eq(idx).addClass('selected');
                    suggestionsList.eq(idx).attr('aria-selected', true);
                    $(this).removeProp('aria-selected');
                    cache.$searchField.each(function () {
                        $(this).attr('aria-activedescendant', suggestionsList.eq(idx)[0].id);
                    });
                } else {
                    suggestionsList.first().addClass('selected');
                    suggestionsList.first().attr('aria-selected', true);
                    cache.$searchField.each(function () {
                        $(this).attr('aria-activedescendant', suggestionsList.first()[0].id);
                    });
                }
                return false;
            }
            return true;
        });
    }
}

module.exports = function () {
    cache.$searchField.each(function () {
        /**
         * Use debounce to avoid making an Ajax call on every single key press by waiting a few
         * hundred milliseconds before making the request. Without debounce, the user sees the
         * browser blink with every key press.
         */
        var debounceSuggestions = debounce(getSuggestions, 300);
        $(this).on('keyup focus', function (e) {
            // Capture Down/Up Arrow Key Events
            switch (e.which) {
                case DOWN_KEY:
                    handleArrow(DIRECTION_DOWN);
                    e.preventDefault(); // prevent moving the cursor
                    break;
                case UP_KEY:
                    handleArrow(DIRECTION_UP);
                    e.preventDefault(); // prevent moving the cursor
                    break;
                default:
                    debounceSuggestions(this, e);
            }
        }).on('click', function (event) {
            event.preventDefault();
        });
    });

    cache.$body.on('click', function (e) {
        const $suggestions = $('.suggestions');

        if (!$suggestions.length) {
            return;
        }

        if ($(e.target).is('.suggestions')) {
            return;
        }

        if (!$suggestions.has(e.target).length && !$(e.target).hasClass('js-search-field')) {
            if (cache.$searchCheckoutContainer.length) transformCheckoutSearch('onDismiss');
            cache.$suggestions.hide();
            toggleSuggestionsIcon('search');
            tearDownSuggestions();
        }
    });
    cache.$document
        .on('click', '.js-reset-button', function () {
            if (cache.$searchCheckoutContainer.length) transformCheckoutSearch('onDismiss');
            cache.$header.removeClass('search-active');
        })
        .on('change', '.js-suggested-searchFor', function (event) {
            event.stopPropagation();

            typeSearchActive = this.value;

            const $productsSuggested = $('.js-products-suggested');
            const selector = $(`[data-id='${typeSearchActive}']`);
            $productsSuggested.addClass('hide');
            selector.removeClass('hide');
            cache.$body.trigger('buildCarousel', [configProductCarousel, cache.$suggestions.find('.js-suggestion-carousel:visible')]);
        })
        .on('submit', 'form[name="simpleSearch"]', function (e) {
            e.preventDefault();

            const suggestionsList = cache.$suggestions;
            if (suggestionsList.find('.btn-search').length > 0) {
                suggestionsList.find('.btn-search')[0].click();
            }
        })
        .on('click', '.search-lens.menu', function (e) {
            cache.$header.toggleClass('search-active');
            cache.$searchField.trigger('focus');
            searchExtended = !searchExtended;
        })
        .on('click touchend', '.js-reset-button', function (e) {
            cache.$header.removeClass('search-active');
            searchExtended = false;
            cache.$searchField.val('');
            cache.$searchMobile.addClass('d-none');
        })
        .on('click', '.js-suggest-term', function (e) {
            let value = $(this).attr('data-search-string');
            cache.$searchField.val(value);
            getSuggestions($('.js-search-field:visible'));
            
        }).on('click', '.search-lens-mobile.menu', function (e) {
            e.preventDefault();
            cache.$searchMobile.toggleClass('d-none');
        });
};
