const cookieMgr = require('./cookies');

function showNextModal() {
    const modal = $('.modal[data-auto-open]').first();

    if (modal.length === 0) return;

    if (modal.data('appearOnce')) {
        const modalID = modal.attr('id');
        const isLocked = cookieMgr.getCookie(`modalLock-${modalID}`);
        if (!isLocked || isLocked === 'false') {
            modal.modal('show');
            cookieMgr.setCookie(`modalLock-${modalID}`, true);
        }
    } else {
        const autoOpenInterval = modal.data('autoOpenInterval');
        if (!autoOpenInterval) {
            modal.modal('show');
        }

        setTimeout(() => {
            modal.modal('show');
        }, autoOpenInterval);
    }
}

module.exports = {
    init() {
        const modalID = cookieMgr.getCookie('cookie-source-modal');

        if (modalID && modalID !== '' && modalID !== '""') {
            const modalLock = cookieMgr.getCookie(`modalLock-${modalID}`);

            if (!modalLock || modalLock === 'false') {
                $(`#${modalID}`).modal('show');

                cookieMgr.setCookie(`modalLock-${modalID}`, true);
                return;
            }
        }

        showNextModal();
    },
    eventListener() {
        $('.modal').on('hidden.bs.modal', function () {
            const $target = $(this);
            $target.removeAttr('data-auto-open');
            showNextModal();
        });

        $('.modal[data-auto-open]').on('shown.bs.modal', function () {
            const $target = $(this);
            const autoCloseInterval = $target.data('autoCloseInterval');
            if (!autoCloseInterval) {
                return;
            }

            setTimeout(() => {
                $target.modal('hide');
            }, autoCloseInterval);
        });
    },
};
