'use strict';

const $userLogged = $('.js-user-logged');

function clearEcommerceObject() {
    dataLayer.push({ ecommerce: null });
}

function getUserID() {
    return $userLogged.length ? $userLogged.data('user') : null;
}

function gtmProductImpression({ event, items, totalPrice, currency, coupons, payment_type, shipping, transaction_id, customer }) {
    clearEcommerceObject();

    const user_id = getUserID();

    const obj = {
        event,
        ecommerce: {
            user_id,
            items
        }
    }

    if (!user_id) {
        delete obj.ecommerce.user_id;
    }

    switch (event) {
        case 'add_to_cart':
        case 'begin_checkout':
        case 'view_cart':
            obj.ecommerce.value = totalPrice;
            obj.ecommerce.currency = currency;
            break;
        case 'add_payment_info':
            obj.ecommerce.value = totalPrice;
            obj.ecommerce.currency = currency;
            obj.ecommerce.payment_type = payment_type;
            break;
        case 'purchase':
            obj.ecommerce.value = totalPrice;
            obj.ecommerce.currency = currency;
            obj.ecommerce.shipping = shipping;
            obj.ecommerce.transaction_id = transaction_id;
            if(customer) {
                obj.ecommerce.customer = customer;
            }
            break;
    }

    if (coupons) {
        obj.ecommerce.coupons = coupons;
    }

    dataLayer.push(obj);
}

function gtmUserImpression({ event, method, customer_type, user_id, customer}) {
    const obj = {
        user_id,
        event,
        method,
        customer_type,
        customer
    }

    if (!customer_type) {
        delete obj.customer_type;
    }

    if (!user_id) {
        delete obj.user_id;
    }

    if (!customer) {
        delete obj.customer;
    }

    dataLayer.push(obj);
}

function gtmVerifyCap({ cap }) {
    const user_id = getUserID();

    const obj = {
        event: 'check_coverage',
        user_id,
        cap
    }

    if (!obj.user_id) {
        delete obj.user_id;
    }

    dataLayer.push(obj);
}

function gtmNewsletterSubscription() {
    const user_id = getUserID();

    const obj = {
        event: "newsletter_subscription",
        user_id
    }

    if (!user_id) {
        delete obj.user_id;
    }

    dataLayer.push(obj);
}

function gtmCouponApplied({ coupon }) {
    const user_id = getUserID();

    const obj = {
        event: "coupon_applied",
        user_id,
        ecommerce: {
            coupon
        }
    }

    if (!user_id) {
        delete obj.user_id;
    }

    dataLayer.push(obj);
}

function gtmContactFormSubmit({ form_purpose }) {
    const user_id = getUserID();

    const obj = {
        user_id,
        event: "form_submit",
        form_purpose
    }

    if (!user_id) {
        delete obj.user_id;
    }

    dataLayer.push(obj);
}

function gtmProductFilters({ selected_items }) {
    const user_id = getUserID();

    if (selected_items.length) {
        selected_items.forEach((item) => {
            const obj = {
                user_id,
                event: "select_allergens",
                selected_items: {
                    name: item.name
                }
            }

            if (!user_id) {
                delete obj.user_id;
            }

            dataLayer.push(obj);
        });
    }

}

function gtmDownloadCatalog({ file_name }) {
    const user_id = getUserID();

    const obj = {
        user_id,
        event: "file_download",
        file_name
    }

    if (!user_id) {
        delete obj.user_id;
    }

    // Scaricare catalogo
    dataLayer.push(obj);
}

module.exports = {
    gtmProductImpression: gtmProductImpression,
    gtmUserImpression: gtmUserImpression,
    gtmVerifyCap: gtmVerifyCap,
    gtmContactFormSubmit: gtmContactFormSubmit,
    gtmNewsletterSubscription: gtmNewsletterSubscription,
    gtmProductFilters: gtmProductFilters,
    gtmCouponApplied: gtmCouponApplied,
    gtmDownloadCatalog: gtmDownloadCatalog
}
