'use strict';

const base = require('base/components/clientSideValidation');

let errorCount = 0;

function clearForm(form) {
    const invalidFormControl = $(form).find('.form-control.is-invalid');

    invalidFormControl.removeClass('is-invalid');
    errorCount = 0;
}

function scrollToError(input) {
    const $input = $(input);
    let headerHeight = 0;

    if ($('header').length) {
        headerHeight = $('header').innerHeight();
    }
    const tollerance = 100;
    const offsetTop = $input.offset().top - headerHeight - tollerance;

    $('html, body').animate({
        scrollTop: offsetTop
    }, 500);

    input.focus();
}

base.invalid = function () {
    $('form input, form select, form textarea').on('invalid', function (e) {
        e.preventDefault();

        this.setCustomValidity('');
        if (!this.validity.valid) {
            var validationMessage = this.validationMessage;

            const $this = $(this);

            $this.addClass('is-invalid');
            if (this.validity.patternMismatch && $this.data('pattern-mismatch')) {
                validationMessage = $this.data('pattern-mismatch');
            }
            if ((this.validity.rangeOverflow || this.validity.rangeUnderflow)
                && $this.data('range-error')) {
                validationMessage = $this.data('range-error');
            }
            if ((this.validity.tooLong || this.validity.tooShort)
                && $this.data('range-error')) {
                validationMessage = $this.data('range-error');
            }
            if (this.validity.valueMissing && $this.data('missing-error')) {
                validationMessage = $this.data('missing-error');
            }
            if (this.validity.rangeOverflow && $this.data('rangeoverflow-error')) {
                validationMessage = $this.data('rangeoverflow-error');
            }
            if (this.validity.rangeUnderflow && $this.data('rangeunderflow-error')) {
                validationMessage = $this.data('rangeunderflow-error');
            }
            $this.parents('.form-group').find('.invalid-feedback')
                .text(validationMessage);

            if (errorCount === 0) {
                scrollToError(this);
                errorCount++;
            }
        }
    });
}

base.buttonClick = function () {
    $('form button[type="submit"], form input[type="submit"]').on('click', function () {
        // clear all errors when trying to submit the form
        clearForm($(this).parents('form'));
    });
},

    module.exports = base;
