

const cache = {
    $body: $('body'),
    $wishlistCount: $('.js-wishlist-count'),
    $wishlistProducts: $('.js-wishlist-products')
};

function removeFromWishlist() {
    const productId = $(this).data('pid');
    const toBeApplied = false;

    if (localStorage.getItem(productId) != null) {
        localStorage.removeItem(productId);
    }

    localStorage.setItem(productId, toBeApplied);
    $('.product-tile-PLP').find('.js-wishlist-tile[data-pid="' + productId + '"]').removeClass('bg-red');
}

function changeIcon(heart) {
    heart.addClass('bg-red');
}

function displayMessageAndChangeIcon(data, heart) {
    $.spinner().stop();

    if (data.success) {
        changeIcon(heart);
        cache.$wishlistProducts.empty().append($.parseHTML(data.html));
        cache.$wishlistCount.empty().text(data.count);

        $.toast().start({
            type: 'wishlist',
            message: data.msg,
            orientation: 'bottom-right',
            params: {
                productName: data.product.productName
            }
        });
    } else if (data.error) {
        $.toast().start({
            type: 'error',
            message: data.msg,
            orientation: 'bottom-right'
        });
    }
}


module.exports = {
    addInWishlist() {
        cache.$body.on('click', '.js-wishlist-tile', function (e) {
            e.stopImmediatePropagation();
            const heart = $(this);
            const url = heart.data('url');
            const pid = heart.data('pid');

            if (!url || !pid) {
                return;
            }


            $.spinner().start();
            $.ajax({
                url,
                type: 'post',
                dataType: 'json',
                data: {
                    pid,
                },
                success(data) {
                    displayMessageAndChangeIcon(data, heart);

                    cache.$body.trigger('product:AddToWishlist', data);
                },
                error(err) {
                    displayMessageAndChangeIcon(err, heart);
                },
            });
        });

        cache.$body.on('click', '.removeButton.remove-from-wishlist', () => {
            removeFromWishlist();
        });
    },
};
