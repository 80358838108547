let updateMiniCart = true;

const cache = {
    $body: $('body'),
    $minicart: $('.js-minicart'),
    $miniCartProducts: $('.js-minicart-products'),
    $minicartIcon: $('.js-minicart-icon'),
    $miniCartQty: $('.js-minicart-quantity'),
    $containerProductMiniCart: $('.js-containerProductMiniCart')
}

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 */
function displayMessageAndRemoveFromCart(data) {
    $.spinner().stop();
    const status = data.success ? 'alert-success' : 'alert-danger';

    if ($('.add-to-wishlist-messages').length === 0) {
        cache.$body.append('<div class="add-to-wishlist-messages "></div>');
    }
    $('.add-to-wishlist-messages')
        .append(`<div class="add-to-wishlist-alert text-center ${status}">${data.msg}</div>`);

    setTimeout(() => {
        $('.add-to-wishlist-messages').remove();
    }, 1500);
    const $targetElement = $(`a[data-pid="${data.pid}"]`).closest('.product-info').find('.remove-product');
    const itemToMove = {
        actionUrl: $targetElement.data('action'),
        productID: $targetElement.data('pid'),
        productName: $targetElement.data('name'),
        uuid: $targetElement.data('uuid'),
    };
    cache.$body.trigger('afterRemoveFromCart', itemToMove);
    setTimeout(() => {
        $('.cart.cart-page #removeProductModal').modal();
    }, 2000);
}
/**
 * move items from Cart to wishlist
 * returns json object with success or error message
 */
function moveToWishlist() {
    cache.$body.on('click', '.product-move .move', function (e) {
        e.preventDefault();
        const url = $(this).attr('href');
        const pid = $(this).data('pid');
        let optionId = $(this).closest('.product-info').find('.lineItem-options-values').data('option-id');
        let optionVal = $(this).closest('.product-info').find('.lineItem-options-values').data('value-id');
        optionId = optionId || null;
        optionVal = optionVal || null;
        if (!url || !pid) {
            return;
        }

        $.spinner().start();
        $.ajax({
            url,
            type: 'post',
            dataType: 'json',
            data: {
                pid,
                optionId,
                optionVal,
            },
            success(data) {
                displayMessageAndRemoveFromCart(data);
            },
            error(err) {
                displayMessageAndRemoveFromCart(err);
            },
        });
    });
}

module.exports = function () {
    // cart();
    moveToWishlist();
    cache.$minicart.on('count:update', (event, data) => {
        if (data && $.isNumeric(data.quantityTotal)) {
            cache.$miniCartQty.text(data.quantityTotal);

            cache.$minicartIcon.attr({
                'aria-label': data.minicartCountOfItems,
                title: data.minicartCountOfItems,
            });

            cache.$containerProductMiniCart.empty().append(data.templates.miniCartHTML);

            // only needed when new products are added, on plp qty increase this isn't needed
            if (data && data.cart && data.cart.items) {
                const itemsquantities = [];
                const { items } = data.cart;

                items.forEach((item) => {
                    itemsquantities.push({ id: item.id, qty: item.quantity });
                });
                $('.cart-json').data('cart', { itemsquantities });
            }
        }
    });

    cache.$minicart.on('mouseenter focusin touchstart', () => {
        if ($('.search:visible').length === 0) {
            return;
        }
        const $miniCartQty = $('.js-minicart-quantity');

        const url = cache.$minicart.data('action-url');
        const count = parseInt($miniCartQty.text(), 10);

        if (count !== 0 && $('.minicart .popover.show').length === 0) {
            if (!updateMiniCart) {
                $('.minicart .popover').addClass('show');
                return;
            }

            $('.minicart .popover').addClass('show');
            $('.minicart .popover').spinner().start();
            $.get(url, (data) => {
                $('.minicart .popover').empty();
                $('.minicart .popover').append(data);
                updateMiniCart = false;
                $.spinner().stop();
            });
        }
    });
    cache.$body.on('touchstart click', (e) => {
        if ($('.minicart').has(e.target).length <= 0) {
            $('.minicart .popover').removeClass('show');
        }
    });
    $('.minicart').on('mouseleave focusout', (event) => {
        if ((event.type === 'focusout' && $('.minicart').has(event.target).length > 0) ||
            (event.type === 'mouseleave' && $(event.target).is('.minicart .quantity')) ||
            cache.$body.hasClass('modal-open')) {
            event.stopPropagation();
            return;
        }
        $('.minicart .popover').removeClass('show');
    });
    cache.$body.on('change', '.minicart .quantity', function () {
        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            // eslint-disable-next-line no-restricted-globals
            location.reload();
        }
    });
    cache.$body.on('product:afterAddToCart', () => {
        updateMiniCart = true;
    });
    cache.$body.on('cart:update', () => {
        updateMiniCart = true;
    });
};
