function getCarousel($target, config) {
    if (!$target.hasClass('slick-initialized')) {
        $target.slick(config);
    }
}


module.exports = {
    get: function ($target, config) {
        getCarousel($target, config);
    },

    destroy: function ($target) {
        if ($target.hasClass('slick-initialized')) {
            $target.slick('unslick');
        }
    },

    goesTo: function($target, slide) {
        $target.slick('slickGoTo', slide);
    }
}