'use strict'

module.exports = function () {
    $(document).ready(() => {
        const cache = {
            $body: $('body'),
            $accessSubscription: $('#accessSubscriptionBtn'),
            $modalAccessSubscription: $('#modalAccessSubscription'),
            $exclusiveProduct: $('#exclusiveProduct')
        };

        if (cache.$accessSubscription.length > 0) {
            cache.$accessSubscription.click();

            var buttonLogin = cache.$modalAccessSubscription.find('button[type="button"]');

            if(buttonLogin.length > 0) {
                buttonLogin.on('click', function() {
                    cache.$modalAccessSubscription.removeClass('show').addClass('hide');
                    $('.modal-backdrop').addClass('hide').removeClass('show');
                });
            }
        }

        if (cache.$exclusiveProduct.length > 0) {
            window.location.href = cache.$exclusiveProduct.data('redirect');
        }
    });
}

