'use strict';

const { gtmProductImpression, gtmProductFilters, gtmUserImpression, gtmCouponApplied, gtmContactFormSubmit, gtmNewsletterSubscription, gtmVerifyCap, gtmDownloadCatalog } = require('base_bofrost/components/tracking');

const $body = $('body');

module.exports = function () {
    // intercept login from oauth
    const urlParams = new URLSearchParams(window.location.search);
    const oauthLogin = urlParams.get('oauthLogin');
    const oauthRedirect = urlParams.get('redirect');

    if (oauthLogin) {
        gtmUserImpression({
            event: oauthRedirect === 'checkout' ? 'checkout_user_login' : 'user_login',
            method: oauthLogin
        });
    }

    $body
        .on('Tracking:ProductsImpression', (event, context) => {
            gtmProductImpression({
                event: 'view_item_list',
                items: context.products
            });
        })
        .on('Tracking:SelectAllergens', (event, context) => {
            gtmProductFilters({
                selected_items: context.selected_items
            });
        })
        .on('Tracking:SelectProduct', (event, context) => {
            gtmProductImpression({
                event: 'select_item',
                items: [context.product]
            });
        })
        .on('Tracking:ProductDetail', (event, context) => {
            gtmProductImpression({
                event: 'view_item',
                items: [context.product]
            });
        })
        .on('Tracking:AddProduct', (event, context) => {
            gtmProductImpression({
                event: 'add_to_cart',
                items: context.items,
                totalPrice: context.totalPrice,
                currency: context.currency
            });
        })
        .on('Tracking:RemoveProduct', (event, context) => {
            gtmProductImpression({
                event: 'remove_from_cart',
                items: context.items,
                totalPrice: context.totalPrice,
                currency: context.currency
            });
        })
        .on('Tracking:AddToWishlist', (event, context) => {
            gtmProductImpression({
                event: 'add_to_wishlist',
                items: context.items,
            });
        })
        .on('Tracking:Login', (event, context) => {
            gtmUserImpression({
                user_id: context.userID,
                event: context.event,
                method: context.method
            });
        })
        .on('Tracking:Registration', (event, context) => {
            gtmUserImpression({
                event: context.event,
                method: context.method,
                customer_type: context.customer_type,
                customer: context.customer
            });
        })
        .on('Tracking:NewsletterSubscription', (event, context) => {
            gtmNewsletterSubscription();
        })
        .on('Tracking:ZipCode', (event, context) => {
            gtmVerifyCap({ cap: context });
        })
        .on('Tracking:ViewCart', (event, context) => {
            gtmProductImpression({
                event: context.event,
                items: context.items,
                totalPrice: context.totalPrice,
                currency: context.currency,
                coupons: context.coupons
            });
        })
        .on('Tracking:Checkout', (event, context) => {
            gtmProductImpression({
                event: context.event,
                items: context.items,
                totalPrice: context.totalPrice,
                currency: context.currency,
                coupons: context.coupons,
                payment_type: context.payment_type
            });
        })
        .on('Tracking:AppliedCoupon', (event, context) => {
            gtmCouponApplied({
                coupon: context.coupon
            });
        })
        .on('Tracking:SubmitForm', (event, context) => {
            const form_purpose = context;
            gtmContactFormSubmit({ form_purpose });
        })
        .on('Tracking:DownloadCatalog', (event, context) => {
            const file_name = context;
            gtmDownloadCatalog({ file_name });
        })
        .on('Tracking:Purchase', (event, context) => {
            gtmProductImpression(context);
        });
}
