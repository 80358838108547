'use strict';

let menuOpened = false;
const cache = {
    $body: $('body'),
    $navbarToggled: $('.js-navbar-toggled'),
    $dropdown: $('.dropdown:not(.disabled) [data-toggle="dropdown"]')
}

const keyboardAccessibilityStore = require('base/components/keyboardAccessibility');

module.exports =
    function () {
        var isDesktop = function () {
            return $(window).width() > 992;
        };

        keyboardAccessibilityStore(
            '.navbar-header .store',
            {
                40() { // down
                },
                38() { // up
                },
                27() { // escape
                    $('.navbar-header .store .popover').removeClass('show');
                    $('.store').attr('aria-expanded', 'false');
                },
                9() { // tab
                },
            },
            () => {
                const $popover = $('.store.popover li.nav-item');
                return $popover;
            },
        );

        cache.$body.on('click', '.js-navbar-toggler', function (e) {
            e.preventDefault();

            const $this = $(this);
            menuOpened = !menuOpened;

            if (menuOpened) {
                $this.removeClass('icon-navigation-drawer-1');
                $this.addClass('icon-close');
                cache.$navbarToggled.addClass('in');
            } else {
                $this.addClass('icon-navigation-drawer-1');
                $this.removeClass('icon-close');
                cache.$navbarToggled.removeClass('in');
            }
        }).on('click', '.js-close-menu', function () {
            if (!isDesktop()) {
                const $this = $(this);

                $this.parents('.dropdown-menu')
                    .attr('aria-hidden', 'true')
                    .parent()
                    .removeClass('show')
                    .find('.dropdown-toggle')
                    .attr('aria-expranded', 'false')
                    .removeClass('active');

                $this.parent().remove();
            }
        });

        cache.$dropdown
            .on('click', function (e) {
                const $this = $(this);

                if (!isDesktop() && $this.hasClass('nav-link')) {
                    // copy parent element into current UL
                    var li = $('<li class="dropdown-item top-category" role="button"></li>');
                    var link = $this.clone().removeClass('dropdown-toggle')
                        .removeClass('full-width')
                        .removeAttr('data-toggle')
                        .removeAttr('aria-expanded')
                        .attr('aria-haspopup', 'false');

                    link.find('i').remove();    
                    link.addClass('d-inline-block vertical-middle size-regular');
                    li.append(link);
                    var closeMenu = $('<span class="nav-menu js-close-menu icons icon-chevron-left cl-blue size-large d-inline-block vertical-middle p-2"></span>');
                    li.prepend(closeMenu);
                    $this.parent().children('.dropdown-menu')
                        .prepend(li)
                        .attr('aria-hidden', 'false');
                    // copy navigation menu into view
                    $this.parent().addClass('show');
                    $this.attr('aria-expanded', 'true');
                    $(link).trigger('focus');
                    $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'true');
                    e.preventDefault();
                }
            })
            .on('mouseenter', function () {
                if (isDesktop()) {
                    var eventElement = this;
                    $('.navbar-nav > li').each(function () {
                        if (!$.contains(this, eventElement)) {
                            const $this = $(this);

                            $this.find('.show').each(function () {
                                clearSelection(this);
                            });
                            if ($this.hasClass('show')) {
                                $this.removeClass('show');
                                $this.children('ul.dropdown-menu').removeClass('show');
                                $this.children('.nav-link').attr('aria-expanded', 'false');
                            }
                        }
                    });

                    const $this = $(this);
                    // need to close all the dropdowns that are not direct parent of current dropdown
                    $this.parent().addClass('show');
                    $this.siblings('.dropdown-menu').addClass('show');
                    $this.attr('aria-expanded', 'true');
                }
            })
            .parent()
            .on('mouseleave', function () {
                if (isDesktop()) {
                    const $this = $(this);

                    $this.removeClass('show');
                    $this.children('.dropdown-menu').removeClass('show');
                    $this.children('.nav-link').attr('aria-expanded', 'false');
                }
            });
    }
