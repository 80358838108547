'use strict';

const base = require('base/login/login');
const formValidation = require('../components/formValidation');
const urlUtils = require('../components/urlUtils');

const cache = {
    $body: $('body'),
    $formLogin: $('.js-login'),
    $responseresetmodalpassword: $('.js-response-reset-modal-password'),
    $formResetModal: $('.form-reset-password'),
    $modalLogin: $('#modalLogin'),
    $checkoutLogin: $('.checkout-login')
}

const isCheckoutLogin = cache.$checkoutLogin.length > 0;

let targetTrigger = isCheckoutLogin  ? 'checkoutLogin' : 'login';

base.init = function() {
    const params = urlUtils.getUrlVars();
    var isAuthenticated = $('.menu-group-right').data('loginopenmodal');
    if (params['login'] === 'true' && isAuthenticated === false) {
        cache.$modalLogin.modal('show');
    }
}

base.login = function () {
    cache.$body.on('submit', '.js-login', function (e) {
        e.preventDefault();
        var form = $(this);
        var url = form.attr('action');
        form.spinner().start();

        
        cache.$body.trigger(targetTrigger + ':submit', e);

        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: form.serialize(),
            success: function (data) {
                form.spinner().stop();
                if (!data.success) {
                    formValidation(form, data);
                    cache.$body.trigger(targetTrigger + ':error', data);
                } else {
                    cache.$body.trigger(targetTrigger + ':success', data);
                    location.href = data.redirectUrl;
                }
            },
            error: function (data) {
                if (data.responseJSON.redirectUrl) {
                    window.location.href = data.responseJSON.redirectUrl;
                } else {
                    cache.$body.trigger(targetTrigger + ':error', data);
                    form.spinner().stop();
                }
            }
        });
        return false;
    });
}

function handlerResetPasswordData(data, { isModal }) {
    if (isModal) {
        cache.$responseresetmodalpassword.append('<div class="alert alert-info">' + data.receivedMsgBody + '</div>');
    } else {
        $('.request-password-title').text(data.receivedMsgHeading);
        $('.request-password-body').empty().addClass('py-4 cl-blue').append('<p>' + data.receivedMsgBody + '</p>');
        if (!data.mobile) {
            $('#submitEmailButton').text(data.buttonText).attr('data-dismiss', 'modal');
        } else {
            cache.$formResetModal.find('.send-email-btn').empty();
        }
    }
}

base.resetPasswordModal = function () {
    cache.$body.on('submit', '.reset-password-form-modal', function (e) {
        e.preventDefault();
        var form = $(this);
        var url = form.attr('action');
        form.spinner().start();
        cache.$body.trigger(targetTrigger + ':register', e);

        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: form.serialize(),
            success: function (data) {
                form.spinner().stop();
                cache.$responseresetmodalpassword.empty();
                if (!data.success) {
                    formValidation(form, data);
                } else {
                    handlerResetPasswordData(data, { isModal: true })
                }
            },
            error: function (error) {
                console.error(error);
                form.spinner().stop();
            }
        });
    });
}

base.resetPassword = function () {
    cache.$body.on('submit', '.form-reset-password', function (e) {
        e.preventDefault();
        var form = $(this);
        var url = form.attr('action');
        form.spinner().start();
        cache.$body.trigger(targetTrigger + ':register', e);

        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: form.serialize(),
            success: function (data) {
                form.spinner().stop();
                if (!data.success) {
                    formValidation(form, data);
                } else {
                    handlerResetPasswordData(data, { isModal: false })
                }
            },
            error: function (error) {
                console.error(error);
                form.spinner().stop();
            }
        });
    });
}

module.exports = base;
