'use strict';

window.jQuery = window.$ = require('jquery');
const processInclude = require('base/util');

$(document).ready(() => {
    processInclude(require('base_bofrost/main'));
    processInclude(require('base_bofrost/components/menu'));
    processInclude(require('./components/trackingEvents'));
    processInclude(require('./eventListener'));
    processInclude(require('./exclusiveProducts.js'));
});

require('base_bofrost/thirdParty/bootstrap');
require('base_bofrost/thirdParty/slick');
require('base/components/spinner');
