require('popper.js/dist/umd/popper.min.js');
require('bootstrap/js/src/util.js');
require('bootstrap/js/src/alert.js');
// require('bootstrap/js/src/button.js');
require('bootstrap/js/src/carousel.js');
require('bootstrap/js/src/collapse.js');
// require('bootstrap/js/src/dropdown.js');
require('bootstrap/js/src/modal.js');
require('bootstrap/js/src/scrollspy.js');
require('bootstrap/js/src/tab.js');
require('bootstrap/js/src/tooltip.js');
// require('bootstrap/js/src/popover.js');

$('[data-accordion] [data-target]').on('click', function () {
    const accordion = $(this).closest('[data-accordion]');

    accordion.find("[data-toggle='collapse']").not(this).each(function () {
        const otherTarget = $(this).data('target');
        $(otherTarget).collapse('hide');
    });
});
