'use strict';

const bonusProducts = require('../product/bonusProducts');
const mobileAppHelper = require('../components/mobileAppHelpers');
const {addToCart, removeToast} = require('../product/tile');

const cache = {
    $body: $('body'),
    $document: $(document),
    $window: $(window),
    $subTotal: $('.sub-total'),
    $showPromotionsBtn: $('.js-promotion-list-btn'),
    $showPromotions: $('.js-promotion-list'),
    $totalItems: $('.js-total-items'),
    $productCard: $('.js-productCard'),
    $productCards: $('.js-product-cards'),
    $changebonusproduct: $('.js-change-bonusProduct'),
    $promoCodeList: $('.js-promo-code-list'),
    $cartInfo: $('.js-cart-info'),
    $miniCartProducts: $('.js-minicart-products'),
    $miniCartQty: $('.js-minicart-quantity'),
    $containerProductMiniCart: $('.js-containerProductMiniCart'),
    $promotions: $('.js-promotions'),
    $orderDiscount: $('.js-order-discount'),
    $cartProduct: $('.js-cart-product:not(.js-promo-card)'),
    $cartPromoCard: $('.js-promo-card'),
    $cartTotal: $('.js-cart-total'),
    $cartBody: $('.cart-body'),
    $checkout: $('.js-checkout')
}
/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
        'fade show" role="alert">' +
        '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
        '<span aria-hidden="true">&times;</span>' +
        '</button>' + message + '</div>';

    $('.cart-error').append(errorHtml);
}
// /**
//  * re-renders the approaching discount messages
//  * @param {Object} approachingDiscounts - updated approaching discounts for the cart
//  */
// function updateApproachingDiscounts(approachingDiscounts) {
//     var html = '';
//     $('.approaching-discounts').empty();
//     if (approachingDiscounts.length > 0) {
//         approachingDiscounts.forEach(function (item) {
//             html += '<div class="single-approaching-discount text-center">'
//                 + item.discountMsg + '</div>';
//         });
//     }
//     $('.approaching-discounts').append(html);
// }

/**
 * Increase quantity cart Item
 * @param {JQuery<HTMLElement>} selector - selector to increase
 * @param {JQuery<HTMLElement>} $this - button increase selector
*/
function increaseQuantity(selector, $this) {
    const maxQuantity = parseInt(selector.attr('data-max-quantity'), 10);
    const quantityValue = selector[0].value;
    let parsedQuantityValue = parseInt(quantityValue, 10);

    const $decreaseSelector = $this.parent().find('.js-update-quantity.decrease');

    if (maxQuantity > quantityValue) {
        ++parsedQuantityValue;
        selector[0].value = parsedQuantityValue;

        if (parsedQuantityValue === maxQuantity) {
            $this.attr('disabled', true);
        }

        if (parsedQuantityValue > 1) {
            $decreaseSelector.removeAttr('disabled')
        }

    } else {
        $this.attr('disabled', true);
    }
}

/**
 * Increase quantity cart item
 * @param {JQuery<HTMLElement>} selector - selector to increase
 * @param {JQuery<HTMLElement>} $this - button decrease selector
*/
function decreaseQuantity(selector, $this) {
    const maxQuantity = parseInt(selector.attr('data-max-quantity'), 10);
    const quantityValue = selector[0].value;
    let parsedQuantityValue = parseInt(quantityValue, 10);

    const $increaseSelector = $this.parent().find('.js-update-quantity.increase');

    if (quantityValue > 1) {
        --parsedQuantityValue;
        selector[0].value = parsedQuantityValue;

        if (parsedQuantityValue === 1) {
            $this.attr('disabled', true);
        }

        if (maxQuantity >= quantityValue) {
            $increaseSelector.removeAttr('disabled');
        }

    } else {
        $this.attr('disabled', true);
    }
}

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    let newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map((key) => `${key}=${encodeURIComponent(params[key])}`).join('&');

    return newUrl;
}

function updatePromoList(html) {
    cache.$promotions.html($.parseHTML(html));
}

/**
 * update items price on quantity
 * @param {string} data - data
 */
function updatePromo(data) {
    const html = $.parseHTML(data.totals.discountsPromoHtml);
    const output = $('<output>').append(html);
    const $promoCardTemp = $('.js-promo-card', output);

    $('body').append('<div id="tempDom"></div>');
    const $tempDom = $('#tempDom').append($promoCardTemp);
    const $promoCard = $('.js-promo-card');
    if ($promoCard.length) {
        $promoCard.each(function () {
            let $this = $(this);
            const attr = $this.attr('data-p-code');
            const item = $tempDom.find(`[data-p-code='${attr}']`);
            if (!item.length) {
                $this.remove();
            }
        });
    }

    $tempDom.remove();

    if ($promoCardTemp.length) {
        $promoCardTemp.each(function () {
            let $this = $(this);
            const attr = $this.attr('data-p-code');
            const $promoCard = $(`.js-promo-card[data-p-code='${attr}']`);
            if (!$promoCard.length) {
                cache.$productCards.append($this);
            }
        });
    }
    var discountArray = data.totals.discounts;
    discountArray.forEach(function (item) {
        let promoCardContainer = $('.js-promo-card[data-p-code="' + item.UUID + '"]');
        promoCardContainer.find('.js-price-total').text(item.price);
        promoCardContainer.find('.js-hidden-price').text(item.price);
    });

    if (!data.hasBonusProduct) {
        $('.js-bonus-product').remove();
    }
}

/**
 * update items price on quantity
 * @param {string} data - data
 */
function updateItems(data) {
    const items = data.items;

    for (let item of items) {
        let $selector = $(`.js-cart-product[data-p-code=${item.id}`);

        const htmlPriceTotal = item.priceTotal.nonAdjustedPrice && !item.price.list ? `<span class="cl-red"><s>${item.priceTotal.nonAdjustedPrice}</s></span><br> ${item.priceTotal.price}` : item.priceTotal.price;

        $selector.find('.js-price-total')
            .empty()
            .append(htmlPriceTotal);

        const htmlPrice = item.price.list ? `<span class="cl-red"><s>${item.price.list.formatted}</s></span><br> ${item.price.sales.formatted}` : item.price.sales.formatted;
        $selector.find('.js-price')
            .empty()
            .append(htmlPrice);
    }
}

/**
 * update items price on quantity
 * @param {string} data - data
 * @param {Object} $target - selector
 * @param {string} pid - product id
 */
function updateItem(data, $target, pid) {
    const items = data.items;
    const itemToUpdate = items.find((item) => item.id === pid);
    const priceTotal = itemToUpdate.priceTotal.nonAdjustedPrice && !itemToUpdate.price.list ? `<span class="cl-red"><s>${itemToUpdate.priceTotal.nonAdjustedPrice}</s></span><br> ${itemToUpdate.priceTotal.price}` : itemToUpdate.priceTotal.price;;

    $target.find('.js-price-total')
        .empty()
        .append(priceTotal);

    // PROMO
    const $bonusProducts = $('.js-bonus-product');

    let bonusProducts = [];
    if ($bonusProducts.length) {
        for (let i = 0; items.length > i; i++) {
            let product = items[i];

            if (product.bonusProducts) {
                bonusProducts = bonusProducts.concat(product.bonusProducts);
            }
        }
    }

    $bonusProducts.each(function () {
        let $this = $(this);
        let uuid = $this.data('p-uuid');

        let uuidExist = bonusProducts.length && bonusProducts.find((bonus) => bonus && bonus.uuid === uuid);

        if (!uuidExist) {
            $this.remove();
        }
    });

    updatePromo(data);
    updateCartTotals(data);
    mobileAppHelper.notifyMinicartUpdate(data.numItems);
}

function updateCartTotals(data) {
    const $subTotal = $('.sub-total');

    $subTotal.empty().append(data.totals.subTotal);
    cache.$totalItems.empty().append(data.resources.totalItems);

    const $boxLimit = $('.js-box-limit');

    if (data.limitOrder.invalid) {
        const template = $.parseHTML(data.limitOrder.template);
        cache.$cartInfo.empty().append(template);
        $('.js-cart-button').attr('disabled', true);
    } else {
        cache.$cartInfo.empty();
        $('.js-cart-button').removeAttr('disabled');
    }

    cache.$orderDiscount.empty().append($.parseHTML(data.totals.orderLevelDiscountTotalHTML));
}

function removeItem($target, response) {
    $target.remove();
    cache.$miniCartQty.text(response.basket.numItems);
    mobileAppHelper.notifyMinicartUpdate(response.basket.numItems);

    // $('.totalCartStyle').text(response.total + response.basket.totals.grandTotal);
    if (response.basket.numItems == 0) {
        cache.$containerProductMiniCart.empty().append(response.templates.miniCartHTML);
    }
}

// function selectBonusProduct() {
//     $(document).on('click', '.select-bonus-product', function () {
//         var $choiceOfBonusProduct = $(this).parents('.choice-of-bonus-product');
//         var pid = $(this).data('pid');
//         var maxPids = $('.choose-bonus-product-dialog').data('total-qty');
//         var submittedQty = parseInt($choiceOfBonusProduct.find('.bonus-quantity-select').val(), 10);
//         var totalQty = 0;
//         $.each($('#chooseBonusProductModal .selected-bonus-products .selected-pid'), function () {
//             totalQty += $(this).data('qty');
//         });
//         totalQty += submittedQty;
//         var optionID = $choiceOfBonusProduct.find('.product-option').data('option-id');
//         var valueId = $choiceOfBonusProduct.find('.options-select option:selected').data('valueId');
//         if (totalQty <= maxPids) {
//             var selectedBonusProductHtml = ''
//                 + '<div class="selected-pid row" '
//                 + 'data-pid="' + pid + '"'
//                 + 'data-qty="' + submittedQty + '"'
//                 + 'data-optionID="' + (optionID || '') + '"'
//                 + 'data-option-selected-value="' + (valueId || '') + '"'
//                 + '>'
//                 + '<div class="col-sm-11 col-9 bonus-product-name" >'
//                 + $choiceOfBonusProduct.find('.product-name').html()
//                 + '</div>'
//                 + '<div class="col-1"><i class="fa fa-times" aria-hidden="true"></i></div>'
//                 + '</div>'
//                 ;
//             $('#chooseBonusProductModal .selected-bonus-products').append(selectedBonusProductHtml);
//             $('.pre-cart-products').html(totalQty);
//             $('.selected-bonus-products .bonus-summary').removeClass('alert-danger');
//         } else {
//             $('.selected-bonus-products .bonus-summary').addClass('alert-danger');
//         }
//     });
// }

function updateCartItem($this) {
    const $parent = $this.parent();
    const actionUrl = $parent.attr('data-action');

    if (!actionUrl) {
        return;
    }

    const $lineProduct = $parent.closest('.js-cart-product');
    const pid = $parent.attr('data-pid');
    const uuid = $parent.attr('data-uuid');
    const quantity = $lineProduct.find('.js-quantity-button').val();
    const urlParams = {
        pid,
        uuid,
        quantity: parseInt(quantity)
    }
    const url = appendToUrl(actionUrl, urlParams);

    $.ajax({
        url,
        type: 'get',
        dataType: 'json',
        success: function (response) {
            if (!response.error) {
                updateItem(response.basketModel, $lineProduct, pid);
                updatePromoList(response.templates.promotionsHTML);
                updateGiftCardItem(response.basketModel.giftCard);
                $('.js-promo-code-list').empty().append(response.basketModel.totals.discountsHtml);
                cache.$body.trigger('product:AfterUpdateQuantityFromCart', response);
            }
        },
        error: function (error) {
            console.error(error);
        }
    });
}

function updateGiftCardItem(giftCard) {
    if(giftCard.template){
        cache.$body.find('.js-gift-card').remove();
        cache.$productCards.append(giftCard.template);
    }
}

function trackingCartImpression() {
    const totalPrice = cache.$cartTotal.data('total');
    const currency = cache.$cartTotal.data('currency');
    const $coupons = $('.js-coupon-applied');
    let coupons;

    let items = [];

    if ($coupons.length) {
        coupons = [];

        $coupons.each(function () {
            coupons.push($(this).data('code'))
        })
    }

    cache.$cartProduct.each(function () {
        let $this = $(this);

        let item = {
            item_id: $this.data('p-code') ? $this.data('p-code').substr(2) : null,
            item_name: $this.data('p-name'),
            discount: $this.data('p-discount') ? $this.data('p-discount') : 0,
            currency: $this.data('p-currency'),
            price: $this.data('p-price'),
            item_category: $this.data('p-category-name'),
            item_category2: $this.data('p-category2-name'),
            promotion_id: $this.data('p-promotion-id'),
            promotion_name: $this.data('p-promotion-name')
        }

        if (!item.item_category) {
            delete item.item_category;
        }

        if (!item.promotion_id) {
            delete item.promotion_id;
        }

        if (!item.promotion_name) {
            delete item.promotion_name;
        }

        items.push(item);
    });

    if (cache.$cartPromoCard.length) {
        const promo = [];

        cache.$cartPromoCard.each(function () {
            let $this = $(this);

            promo.push({
                item_id: $this.data('p-id'),
                item_name: $this.data('p-name'),
                price: $this.data('p-price'),
                currency: $this.data('p-currency'),
                quantity: 1
            })
        });

        items = items.concat(promo);
    }

    if (coupons) {
        coupons = coupons.join('|');
    }

    cache.$body.trigger('Tracking:ViewCart', { event: 'view_cart', items, totalPrice, currency, coupons });
}

module.exports = function () {
    bonusProducts.addBonusProductsToCart();
    bonusProducts.showMoreBonusProducts();

    if (cache.$cartBody.length && !cache.$checkout.length) {
        trackingCartImpression();
        addToCart();
        removeToast();
    }

    cache.$body
        .on('click touchend', '.js-remove-product', function (event) {
            event.preventDefault();
            const $this = $(this);
            const actionUrl = $this.attr('data-action');
            const $lineProduct = $this.closest('.js-cart-product');
            const pid = $this.attr('data-pid');
            const uuid = $this.attr('data-uuid');

            const urlParams = {
                pid,
                uuid
            }

            const $minicart = $this.parents('.js-minicart-content');

            if ($minicart.length) {
                $minicart.spinner().start();
            }

            const url = appendToUrl(actionUrl, urlParams);

            $.ajax({
                url,
                type: 'get',
                dataType: 'json',
                success: function (response) {
                    if ($minicart.length) {
                        $minicart.spinner().stop();
                    }

                    removeItem($lineProduct, response);
                    updatePromo(response.basket);
                    updateCartTotals(response.basket);
                    updatePromoList(response.templates.promotionsHTML);
                    updateGiftCardItem(response.basket.giftCard);
                    cache.$body.trigger('product:AfterRemoveFromCart', response);
                },
                error: function (error) {
                    console.error(error);

                    if ($minicart.length) {
                        $minicart.spinner().stop();
                    }
                }
            });
        })
        .on('click touchend', '.js-remove-bonusProduct', function (event) {
            event.preventDefault();
            const $this = $(this);
            const actionUrl = $this.attr('data-action');
            const $lineProduct = $this.closest('.js-cart-product');
            const pid = $this.attr('data-pid');
            const uuid = $this.attr('data-uuid');

            const urlParams = {
                pid,
                uuid
            }

            const url = appendToUrl(actionUrl, urlParams);
            var icon = $(this);
            $.ajax({
                url,
                type: 'get',
                dataType: 'json',
                success: function (response) {
                    removeItem($lineProduct, response);
                    updatePromo(response.basket);
                    updateCartTotals(response.basket);
                    icon.hide();
                    $('.addBonusProductIcon[data-pid="' + icon.data('pid') + '"]').show();
                },
                error: function (error) {
                    console.error(error);
                }
            });
        })
        .on('change', '.js-replacement-toggle', function (event) {
            event.preventDefault();
            const $this = $(this);

            const actionUrl = $this.attr('data-action');
            const val = event.target.checked;
            const urlParams = { val }

            const url = appendToUrl(actionUrl, urlParams);

            $.ajax({
                url,
                type: 'get',
                dataType: 'json',
                error: function (error) {
                    console.error(error);
                }
            });
        })
        .on('change', '.js-sale-conditions', function (event) {
            event.preventDefault();
            const $this = $(this);

            const actionUrl = $this.attr('data-action');
            const val = event.target.checked;
            const urlParams = { val }

            const url = appendToUrl(actionUrl, urlParams);

            $.ajax({
                url,
                type: 'get',
                dataType: 'json',
                success: function (data){
                    if (data.basketModel.saleConditionsAccepted) {
                        $('.js-submit-shipping').removeAttr('disabled');
                    } else {
                        $('.js-submit-shipping').attr('disabled', true);
                    }
                }
            });
        })
        .on('submit', '.promo-code-form', function (event) {
            event.preventDefault();
            $.spinner().start();

            var $form = $(this);
            const $promoCodeFormControl = $form.find('.form-control');
            const $couponMissingError = $('.coupon-missing-error');
            const $couponErrorMessage = $('.coupon-error-message');
            const $couponCodeField = $('.coupon-code-field');

            $couponMissingError.hide();
            $couponErrorMessage.empty();
            if (!$couponCodeField.val()) {
                $promoCodeFormControl.addClass('is-invalid');
                $promoCodeFormControl.attr('aria-describedby', 'missingCouponCode');
                $couponMissingError.show();
                $.spinner().stop();
                return false;
            }
            $promoCodeFormControl.removeClass('is-invalid');
            $couponErrorMessage.empty();
            cache.$body.trigger('promotion:beforeUpdate');

            $.ajax({
                url: $form.attr('action'),
                type: 'GET',
                dataType: 'json',
                data: $form.serialize(),
                success: function (data) {
                    if (data.error) {
                        $promoCodeFormControl.addClass('is-invalid');
                        $promoCodeFormControl.attr('aria-describedby', 'invalidCouponCode');
                        $couponMissingError.text(data.errorMessage);
                        $couponMissingError.show();
                    } else {
                        $('.js-promo-code-list').empty().append(data.totals.discountsHtml);
                        updateCartTotals(data);
                        updateGiftCardItem(data.giftCard);
                        updatePromoList(data.templates.promotionsHTML);

                        if (cache.$productCard.length > 0) {
                            cache.$productCard.empty().append(data.templates.cartHTML);
                        }

                        if (data.couponCodeApplied) {
                            cache.$body.trigger('promotion:success', { coupon: data.couponCodeName });
                        }
                    }
                    $couponCodeField.val('');
                    $.spinner().stop();
                },
                error: function (err) {
                    cache.$body.trigger('promotion:error', err);
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.errorMessage);
                        $.spinner().stop();
                    }
                }
            });
            return false;
        })
        .on('click', '.remove-coupon', function (e) {
            e.preventDefault();

            const $this = $(this);

            let url = $this.data('action');
            const uuid = $this.data('uuid');
            const couponCode = $this.data('code');
            const urlParams = {
                code: couponCode,
                uuid: uuid
            };

            url = appendToUrl(url, urlParams);

            cache.$body.trigger('promotion:beforeUpdate');
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                success: function (data) {
                    $('.coupon-uuid-' + uuid).remove();
                    updateCartTotals(data);
                    updateGiftCardItem(data.giftCard);
                    updatePromoList(data.templates.promotionsHTML);

                    if (cache.$productCard.length > 0) {
                        cache.$productCard.empty().append(data.templates.cartHTML);
                    }

                    $.spinner().stop();
                },
                error: function (err) {
                    cache.$body.trigger('promotion:error', err);
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        $.spinner().stop();
                    }
                }
            });
        })
        .on('click', '.js-update-quantity.increase', function (e) {
            const buttonValue = 'input.js-quantity-button';
            const $this = $(this);
            const $quantitySelector = $this.parent().find(buttonValue);

            increaseQuantity($quantitySelector, $this);
            updateCartItem($this);
        })
        .on('click', '.js-update-quantity.decrease', function (e) {
            const buttonValue = 'input.js-quantity-button';
            const $this = $(this);
            const $quantitySelector = $this.parent().find(buttonValue);
            decreaseQuantity($quantitySelector, $this);
            updateCartItem($this);
        });

    cache.$body.on('click', '.js-promotion-list-btn', function (e) {
        e.preventDefault();
        cache.$promotions.toggleClass('open-promo');
    });

    cache.$body.on('click', '.js-bonus-product-button', function () {
        $.spinner().start();
        $(this).addClass('launched-modal');
        $.ajax({
            url: $(this).data('url'),
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                bonusProducts.chooseBonusProducts(data);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    cache.$body.on('click', '.js-cart-button', function () {
        window.location.href = $(this).data('href');
    });
}
