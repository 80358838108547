"use strict";

module.exports = () => {
  $(document).ready(() => {
    let currentPage = window.location.href;
    let menuItems = document.getElementsByClassName("menu-item");
    for (let elem of menuItems) {
      if (currentPage.indexOf(elem.classList[1]) > -1) {
        elem.classList.add("active");
      } else {
        if (elem.classList.contains("active")) {
          elem.classList.remove("active");
        }
      }
    }
  });
};
