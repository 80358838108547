const formValidation = require('../components/formValidation');
const verifyZip = require('../components/verifyZip');

const cache = {
    $body: $('body'),
    $checkoutContainer: $('.js-checkout'),
    $dateSelectorBlock: $('.js-date-selector-block'),
    $addressSelectorBlock: $('.js-address-selector-block'),
    $shippingForm: $('.js-shipping-form'),
    $addressModalButton: $('.addressModalButton')
};

function clearControllerErrors() {
    const $controllerError = $('.controller-error');
    const $serverError = $('.server-error');

    if ($controllerError.length) {
        $controllerError.remove();
    }

    if ($serverError.length) {
        $serverError.remove();
    }
}

function pricebookChangedConfirmBtn() {
    cache.$body.on('click', '#btn-pricebookChanged-modal', function () {
        const modal = $('#pricebookChanged-modal');
        modal.removeClass('show');
        modal.addClass('hide');
    });
}

function changeShippingAddress() {
    cache.$body.on('click', '.js-change-address', function (e) {
        e.preventDefault();
        const $this = $(this);
        cache.$addressSelectorBlock.find('.js-shipment-selectors').addClass('open');
        $this.addClass('disabled');
    });
}

function changeShippingAddressEvent() {
    cache.$body.on('change', '.js-shipping-address', function (e) {
        const $this = $(this);
        const url = cache.$addressSelectorBlock.find('.js-shipment-selectors').attr('data-url');
        const method = 'POST';

        const payload = {
            geocallAddressID: $this.attr('data-geocall-id'),
            addressID: $this.val(),
        };

        clearControllerErrors();

        $.spinner().start();

        $.ajax({
            url,
            method,
            data: payload,
            dataType: 'json',
            success: function (data) {
                $.spinner().stop();

                if (data.success) {
                    $('.js-shipping-address-field').removeClass('selected');
                    $this.parent('.js-shipping-address-field').addClass('selected');

                    $('.js-change-address').removeClass('disabled');
                    cache.$addressSelectorBlock.find('.js-shipment-selectors').removeClass('open');

                    cache.$dateSelectorBlock.empty().append($.parseHTML(data.deliveryTemplateRender));

                    if (data.pricebookChanged) {
                        const $summaryBlock = $('.summary');
                        $summaryBlock.empty().append($.parseHTML(data.orderTemplateRender));
                        const modal = $('#pricebookChanged-modal');

                        modal.removeClass('hide');
                        modal.addClass('show');

                        pricebookChangedConfirmBtn();
                    }
                } else {
                    if (data.redirectUrl) {
                        window.location.href = data.redirectUrl;
                        return;
                    }

                    cache.$dateSelectorBlock.empty();
                    cache.$checkoutContainer.prepend($.parseHTML(`<div class="server-error controller-error show mb-4">${data.message}</div>`));
                }

                scrollTo(0, 0);
            },
            error: function (error) {
                $.spinner().stop();
            },
        });
    });
}

function addNewAddressEvent() {
    cache.$body.on('submit', '.js-address-form', function (e) {
        e.preventDefault();
        const form = $(this);
        const method = form.attr('method');
        const url = form.attr('action');
        const data = form.serialize();
        const dataType = 'json';

        const $modal = $('#newAddressModal');
        // rimuove l'invalid feedback restituito dall'api
        clearControllerErrors();

        $modal.spinner().start();

        $.ajax({
            url,
            method,
            data,
            dataType,
            success: function (data) {
                if (data.success) {
                    $modal.modal('hide');
                    $('.js-change-address').removeClass('disabled');
                    cache.$dateSelectorBlock.empty().append($.parseHTML(data.deliveryTemplateRender));
                    cache.$addressSelectorBlock.empty().append($.parseHTML(data.addressesTemplateRender));

                    if (data.pricebookChanged) {
                        const $summaryBlock = $('.summary');
                        $summaryBlock.empty().append($.parseHTML(data.orderTemplateRender));

                        pricebookChangedConfirmBtn();
                    }

                    form[0].reset();
                    $('.alert').remove();
                } else {
                    formValidation(form, data);
                    window.location.href = data.redirectUrl;
                }

                $modal.spinner().stop();
            },
            error: function (error) {
                $modal.spinner().stop();
                $modal.find('.modal-body').prepend($.parseHTML(`<div class="invalid-feedback controller-error d-block mb-4">${error.responseJSON.message}</div>`));
            },
        });
    });

    cache.$body.on('submit', '.js-guest-address-form', function (e) {
        e.preventDefault();
        const form = $(this);

        const $modal = $('#newAddressModal');
        clearControllerErrors();

        $modal.spinner().start();

        $.ajax({
            url: form.attr('action'),
            method: form.attr('method'),
            data: form.serialize(),
            dataType: 'json',
            success(data) {
                if (data.success) {
                    $modal.modal('hide');
                    cache.$body.find('.js-shipping-address-field label').empty().html(data.formattedAddress);
                    cache.$body.trigger('checkout:updateDeliveryDates');
                } else {
                    $modal.spinner().stop();
                    if (data.formError) {
                        formValidation(form, data);
                    } else {
                        $modal.find('.modal-body').prepend($.parseHTML(`<div class="invalid-feedback controller-error d-block mb-4">${data.errorMsg}</div>`));
                    }
                }
            },
            error(error) {
                $modal.spinner().stop();
                $modal.find('.modal-body').prepend($.parseHTML(`<div class="invalid-feedback controller-error d-block mb-4">${error.responseJSON.message}</div>`));
            },
        });
    });
}

function changeDeliveryDateEvent() {
    cache.$body.on('click', '.js-change-date', function (e) {
        const $this = $(this);
        const $timeSelectContainer = $('.js-time-select');
        const date = $this.val();
        $timeSelectContainer.addClass('d-none');

        $timeSelectContainer.each(function () {
            const $_this = $(this);
            const dateFor = $_this.attr('data-for');
            const $deliveryTime = $_this.find('.js-delivery-time');

            if (dateFor === date) {
                $_this.removeClass('d-none');
                $deliveryTime.removeAttr('disabled');
                const slotID = $deliveryTime.find(':selected').data('slotid');
                $('.js-timeslotid').val(slotID);
            } else {
                $deliveryTime.attr('disabled', true);
            }
        });
    });
}

function changeDeliveryTimeEvent() {
    cache.$body.on('change', '.js-delivery-time', function (e) {
        const $this = $(this);
        $this.find('option').removeAttr('selected');

        const $selectedOptions = $($this[0].selectedOptions[0]);
        $selectedOptions.attr('selected', true);
        const slotID = $selectedOptions.data('slotid');
        $('.js-timeslotid').val(slotID);
    });
}

function handlePostalCodeValidation() {
    cache.$body.on('keyup', '.address-form-postalCode', (e) => verifyZip(e.target));
}

module.exports = {
    changeShippingAddress: changeShippingAddress,
    changeShippingAddressEvent: changeShippingAddressEvent,
    addNewAddressEvent: addNewAddressEvent,
    changeDeliveryDateEvent: changeDeliveryDateEvent,
    changeDeliveryTimeEvent: changeDeliveryTimeEvent,
    handlePostalCodeValidation: handlePostalCodeValidation,
    pricebookChangedConfirmBtn: pricebookChangedConfirmBtn
}
