const carousel = require('./carousel');

const defaultConfig = {
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    prevArrow: '<span class="slick-arrow slick-prev"></span>',
    nextArrow: '<span class="slick-arrow slick-next"></span>',
    responsive: [
        {
            breakpoint: 768, // Questo breakpoint corrisponde ai dispositivi con larghezza massima di 768px (tablet e dispositivi mobili)
            settings: {
                slidesToShow: 2, // Cambia questo valore per il numero di slide da visualizzare su mobile
                slidesToScroll: 1,
            },
        },
    ],
};

const cache = {
    $body: $('body'),
    $carousel: $('.js-product-carousel'),
    $window: $(window),
};

function parseConfig(dataConfigAttr) {
    try {
        JSON.parse(dataConfigAttr);
    } catch (error) {
        return false;
    }

    return true;
}

function getCarousel(config, $target) {
    let $carousel = $target || cache.$carousel;

    if (!$carousel.length) {
        $carousel = $('.js-product-carousel');
    }

    if (!config) {
        const dataConfig = $carousel.attr('data-config');
        config = parseConfig(dataConfig) ? JSON.parse(dataConfig) : defaultConfig;
    }

    carousel.get($carousel, config);
}

// Callback function to execute when mutations are observed
const callback = (mutationList, observer) => {
    for (const mutation of mutationList) {
        if (mutation.type === 'childList') {
            const elements = $(mutation.target).find('.js-product-carousel');

            if (elements.length > 0) {
                const numberOfSlideDesktop = elements.data('numberofproduct');
                const numberOfSlideMobile = elements.data('numberofproductmobile');
                if (numberOfSlideDesktop) {
                    defaultConfig.slidesToShow = numberOfSlideDesktop;
                }

                if (numberOfSlideMobile) {
                    defaultConfig.responsive[0].settings.slidesToShow = numberOfSlideMobile;
                }

                getCarousel(defaultConfig, $(elements[0]));
            }
        }
    }
};

const observer = new MutationObserver(callback);
const obsConfig = { attributes: true, childList: true, subtree: true };

module.exports = function () {
    const numberOfSlideDesktop = cache.$carousel.data('numberofproduct');
    const numberOfSlideMobile = cache.$carousel.data('numberofproductmobile');

    if (numberOfSlideDesktop) {
        defaultConfig.slidesToShow = numberOfSlideDesktop;
    }

    if (numberOfSlideMobile) {
        defaultConfig.responsive[0].settings.slidesToShow = numberOfSlideMobile;
    }

    const target = document.querySelector('.einstein-recommendations');

    if (cache.$carousel.length > 0) { getCarousel(); }

    if (target) {
        observer.observe(target, obsConfig);
    }
    cache.$body.on('buildCarousel', (e, config, $target) => getCarousel(config, $target));
    cache.$body.on('destroyCarousel', (e, $target) => carousel.destroy($target));
};
